import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MenuItems = [
  { label: 'Home', labelEn: 'Home', route: '/', routeEn: '/en/', id: 0 },
  {
    label: 'Unser Trainingsbereich',
    labelEn: 'Our Practice',
    route: '/trainingsbereich/',
    routeEn: '/en/practice/',
    id: 1,
  },
  {
    label: 'Personal Training',
    labelEn: 'Sports Medicine',
    route: '/personal-training/',
    routeEn: '/en/sports-medicine-lactatetest/',
    id: 4,
  },
  {
    label: 'Preise',
    labelEn: 'Sleep Medicine',
    route: '/preise/',
    routeEn: '/en/sleep-medicine-munich/',
    id: 5,
  },
  {
    label: 'Kontakt',
    labelEn: 'Contact',
    route: '/kontakt/',
    routeEn: '/en/contact/',
    id: 9,
  },
];

type Props = { white: boolean };

const Menu: React.FC<Props> = ({ white }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <Container
        css={{
          background: white ? '' : 'var(--primary-color)',
          padding: white ? '' : '2rem',
          paddingTop: white ? '' : '1rem',
          paddingBottom: white ? '' : '1rem',
          margin: white ? '' : '-2rem',
        }}
        white={white}
      >
        {MenuItems.map(item => {
          return (
            <MenuItem key={item.id}>
              <Link
                key={item.id}
                css={{ color: white ? 'white' : 'black' }}
                to={i18n.language === 'de' ? item.route : item.routeEn}
              >
                {i18n.language === 'de' ? item.label : item.labelEn}
              </Link>
            </MenuItem>
          );
        })}
      </Container>
    </>
  );
};

const Container = styled.div`
  a {
    font-size: 1rem;
    text-decoration: none;
    font-weight: normal;
  }

  a:hover {
    color: ${props => (props.white ? 'var(--primary-color)' : 'white')};
  }

  z-index: 500;
`;

const MenuItem = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

export default Menu;
