import React from 'react';
import styled from '@emotion/styled';

const BlogButton: React.FC = () => {
  return (
    <>
      <a href="https://www.neurozentrum-arabellapark.de/blog" id="blog-pages">
        <Button>Blog</Button>
      </a>
    </>
  );
};

const Button = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: white;
  padding: 0.7rem;
  width: 50px;
  text-align: center;
`;

export default BlogButton;
