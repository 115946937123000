import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import DgspJPG from '@assets/images/dgsp.jpg';
import DgspJPGMobile from '@assets/images/dgsp-mobile.jpg';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Image from '~/components/atoms/Image/Image';
import ImportantServices from '~/components/atoms/ImportantServices/ImportantServices';
import BlogButton from '~/components/atoms/BlogButton/BlogButton';

const Footer: React.FC = () => {
  const { i18n } = useTranslation();

  const useGermanFooter = () => {
    return (
      <>
        <Link to="/kontakt/" id="contact">
          Kontakt
        </Link>{' '}
        |{' '}
        <Link to="/impressum/" id="imprint">
          Impressum
        </Link>{' '}
        |{' '}
        <Link to="/datenschutz/" id="data-security">
          Datenschutz{' '}
        </Link>{' '}
        |{' '}
        <Link to="https://www.neurozentrum-arabellapark.de">
          Neurozentrum Arabellapark{' '}
        </Link>
      </>
    );
  };

  const useEnglishFooter = () => {
    return (
      <>
        <Link to="/en/contact/" id="contact">
          Contact
        </Link>{' '}
        |{' '}
        <Link to="/en/imprint/" id="imprint">
          Imprint
        </Link>{' '}
        |{' '}
        <Link to="/datenschutz/" id="data-security">
          Data Security
        </Link>{' '}
        |{' '}
        <Link to="/" id="english-pages">
          Seiten auf Deutsch
        </Link>
      </>
    );
  };

  return (
    <>
      <Container>
        <Flex>
          <ImportantServices />
          <FooterLinks>
            {i18n.language === 'de' ? useGermanFooter() : useEnglishFooter()}
          </FooterLinks>
          <BlogButton />
        </Flex>
        <Flex
          style={{
            marginBottom: '2rem',
            paddingBottom: '2rem',
            paddingTop: '1rem',
          }}
        >
          <FlexItem>
            <Image
              src={DgspJPG}
              srcmobile={DgspJPGMobile}
              alt="DGSP"
              width={80}
            />
          </FlexItem>
        </Flex>
      </Container>
    </>
  );
};

const Container = styled.footer`
  @media (min-width: 1200px) {
    width: 1200px;
    height: 220px;
    max-width: 1200px;
  }

  background-color: var(--primary-color);
  font-size: var(--primary-font-size);
  line-height: 1.5;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const FlexItem = styled.div`
  align-self: center;
`;

const FooterLinks = styled.div`
  padding: 1rem;
  text-align: center;
`;

const JamedaItem = styled.div`
  margin-left: 2rem;
`;

export default Footer;
