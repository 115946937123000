import React, { useState } from 'react';
import styled from '@emotion/styled';
import Header from '~/components/molecules/Header/Header';
import Footer from '~/components/molecules/Footer/Footer';
import MobileCTAHeader from '~/components/molecules/MobileCTAHeader/MobileCTAHeader';
import MobileCTAFooter from '~/components/molecules/MobileCTAFooter/MobileCTAFooter';
import HeroImage from '~/components/organisms/HeroImage/HeroImage';

type Props = {
  desktopHero: string;
  mobileHero: string;
  altHero: string;
  filter?: boolean;
};

const HeaderFooterLayout: React.FC<Props> = ({
  children,
  desktopHero,
  mobileHero,
  altHero,
  filter,
}) => {
  const [menu, setMenu] = useState(false);

  const clickHandler = () => {
    setMenu(!menu);
  };

  const menuClickHandler = () => {
    if (menu) {
      setMenu(false);
    }
  };

  return (
    <>
      <Container>
        <MobileCTAHeader />
        <Header clickHandle={clickHandler} />
        <HeroImage
          src={desktopHero}
          alt={altHero}
          menu={menu}
          srcmobile={mobileHero}
          clickHandle={menuClickHandler}
          filter={filter}
        />
        {children}
        <Footer />
        <MobileCTAFooter />
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

export default HeaderFooterLayout;
